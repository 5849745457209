<template>
  <div class="form__body">
    <!-- input block -->
    <div class="form__body-inputBlock mb-3 pb-3 required">
      <p class="form-label">МПК</p>
      <SelectSingleComponentCheckbox
        :style="{ height: '40px' }"
        v-model="item.mpkId"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="mpkFilterList"
        :canClear="false"
        required
      />
    </div>
    <!-- input block -->
    <div class="form__body-inputBlock mb-3 pb-3">
      <!-- 1 -->
      <div class="required">
        <p class="title-input form-label">Исполнитель</p>
        <SelectMultiComponentCheckbox
          mod="small"
          v-model="item.organizationsIds"
          placeholder="Укажите исполнителей"
          trackBy="text"
          valueProp="id"
          labelOptions="text"
          :options="organizationList"
          required
        />
        <p class="title-subtext">Выберите исполнителей из списка</p>

        <div class="checkboxInput mt-16" v-if="isBusinessCouncilVisible">
          <input type="checkbox" v-model="item.IsBusinessCouncil" id="chBusinessCouncil" class="sr-only" />
          <label for="chBusinessCouncil">Деловой совет</label>
        </div>
      </div>
      <!-- 2 -->
    </div>
    <div class="form__body-inputBlock mb-3 pb-3" v-if="item.id > 0">
      <div>
        <p>Реквизиты</p>
        <ComponentInput placeholder="Введите реквизиты" v-model="item.razdelProtocal" type="text" />
      </div>
    </div>
    <!-- input block -->
    <div class="form__body-inputBlock mb-3 pb-3 required">
      <!-- select -->
      <p class="form-label">Заседание МПК</p>
      <SelectSingleComponentCheckbox
        :style="{ height: '40px' }"
        v-model="item.eventId"
        placeholder="Выберите мероприятие"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="eventList"
        :canClear="false"
        required
        :key="eventList.length"
      />
    </div>

    <div class="form__body-inputBlock mb-3 pb-3">
      <!--			???			-->
      <p class="form-label">Поручение по итогам заседания МПК</p>
      <ComponentInput placeholder="Введите" v-model="item.razdelProtocal" type="text" mod="redesigned" />
    </div>

    <div class="form__body-inputBlock mb-3 pb-3 row">
      <div class="block col-12 pb-3 required">
        <p class="form-label">Срок</p>
        <ComponentInput
          placeholder="Формулировка из поручения Правительства"
          v-model="item.srokText"
          type="text"
          required
          mod="redesigned"
        />
      </div>
      <div class="block col-6" v-if="item.isPermanent">
        <p class="form-label">Срок исполнения</p>
        <div class="calendar-input__container">
          <div class="calendar-wrapper create-project">
            <ComponentInput placeholder="" type="text" :disabled="true" modelValue="" />
          </div>
        </div>
      </div>
      <div class="block col-6 required" v-else>
        <p class="form-label">Срок исполнения</p>
        <div class="calendar-input__container">
          <div class="calendar-wrapper create-project">
            <date-component
              v-model="item.srok"
              modClass="redesigned height-40"
              placeholderForDate=""
              placeholder=""
              :clearable="false"
              dateIcon
              required
            />
          </div>
        </div>
      </div>
      <!-- calendar -->
      <div class="block col-6 calendar-input required">
        <p class="form-label">Срок контроля</p>
        <div class="calendar-input__container">
          <div class="calendar-wrapper create-project">
            <date-component
              v-model="item.deadline"
              modClass="redesigned height-40"
              placeholderForDate=""
              placeholder=""
              :clearable="false"
              dateIcon
              required
            />
          </div>
        </div>
      </div>
      <div class="block col-12 pt-3 block__wrap">
        <div class="checkboxInput col-6">
          <input type="checkbox" v-model="item.isPermanent" id="chPermanent" style="display: none" />
          <label for="chPermanent">Выполняется на постоянной основе</label>
        </div>
        <!--			???			-->
        <div class="col-6">
          <div class="radioInput">
            <input
              type="radio"
              v-model="item.r"
              name="report"
              id="quarterlyReport"
              style="display: none"
              value="quarterlyReport"
              @click="clickRadio('quarterlyReport')"
            />
            <label for="quarterlyReport">Ежеквартальный отчет</label>
          </div>
          <div class="radioInput">
            <input
              type="radio"
              v-model="item.r"
              name="report"
              id="semiAnnualReport"
              style="display: none"
              value="semiAnnualReport"
              @click="clickRadio('semiAnnualReport')"
            />
            <label for="semiAnnualReport">Полугодовой отчет</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form__body-inputBlock mb-3 pb-3 required">
      <!--			???			-->
      <p class="form-label">Номер пункта поручения</p>
      <ComponentInput placeholder="Введите" v-model="item.noPoint" type="text" mod="redesigned" required />
    </div>
    <!-- input block -->
    <div class="form__body-inputBlock mb-3 pb-3 required">
      <p class="form-label">Содержание пункта поручения</p>
      <ComponentInput placeholder="Текст поручения" v-model="item.brief" type="textarea" mod="redesigned" required />
    </div>
    <!-- input block -->
    <div class="form__body-inputBlock">
      <ButtonStock :title="item.id > 0 ? 'Сохранить поручение' : 'Создать поручение'" @click="save" />

      <div class="checkboxInput pt-1" v-if="!item.id || !item.isStart" style="float: right">
        <input class="w-auto hidden" type="checkbox" v-model="item.canComment" id="chbx_canComment" />
        <label for="chbx_canComment" class="form-check-label form-label">На согласование в ФОИВ</label>
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_ORG from '../../../admin/api/organization';
  import API from '../../api/assignment';
  import API_MPK from '../../api/commission';
  import API_EVENT from '../../api/event';

  export default {
    name: 'AssignmentCardAdd',
    components: {
      ButtonStock,
      dateComponent,
      ComponentInput,
      SelectSingleComponentCheckbox,
      SelectMultiComponentCheckbox,
    },
    props: {
      nn: Number,
      id: Number,
    },
    watch: {
      nn() {
        this.init();
      },
      'item.mpkId'() {
        this.changeMpk();
      },
      isBusinessCouncilVisible(value) {
        if (value === false) {
          this.item.IsBusinessCouncil = false;
        }
      },
    },
    mixins: [security],
    data() {
      return {
        item: {
          mpkId: 0,
          eventId: 0,
          organizationsIds: [],
          srok: null,
          deadline: null,
          srokText: null,
          noPoint: null,
          brief: null,
          razdelProtocal: null,
          isPermanent: false,
          r: null,
          IsBusinessCouncil: false,
        },

        mpkList: [],
        eventList: [],
        organizationList: [],
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.MpkAssignmentEdit).then((response) => {
        if (!response.data) {
          this.$emit('close');
        }
      });
      this.init();
    },

    methods: {
      clickRadio(newValue) {
        if (newValue === this.item.r) {
          this.item.r = null;
        }
      },
      init() {
        if (this.v$ && this.v$.$error) {
          this.v$.$reset();
        }
        this.item = {};
        this.item.mpkId = 0;
        this.item.eventId = 0;
        this.item.organizationsIds = [];
        this.item.srok = null;
        this.item.deadline = null;
        this.item.srokText = null;
        this.item.noPoint = null;
        this.item.brief = null;
        this.item.razdelProtocal = null;
        this.item.isPermanent = false;
        this.item.r = null;
        this.item.IsBusinessCouncil = false;

        this.loadMpk();
        this.loadOrganization();

        if (this.id > 0) {
          API.find(this.id).then((response) => {
            const { eventId } = response.data;
            this.eventId = eventId ?? 0;
            this.item = response.data;
            this.item.organizationsIds = response.data.assignmentExecutors.map((x) => x.organizationId);
          });
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = Object.assign({}, this.item);
        itemToSave.assignmentExecutors = null;

        API.update(itemToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && (response.data.id || this.item.id > 0)) {
              this.$emit('save', true);
              this.v$.$reset();
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      loadMpk() {
        API_MPK.searchForList({}).then((response) => {
          this.mpkList = response.data ? response.data.items : [];
        });
      },
      changeMpk() {
        this.eventList = [];

        if (this.item.mpkId > 0) {
          API_EVENT.search({
            pageNumber: 1,
            pageSize: 0,
            mpkId: this.item.mpkId,
          }).then((response) => {
            if (response.data && response.data.items) {
              response.data.items
                .map((x) => ({ id: x.id, text: x.name }))
                .forEach((el) => {
                  this.eventList.push(el);
                });
            }
          });
        }
      },
      loadOrganization() {
        API_ORG.searchShortFormat({
          pageNumber: 1,
          pageSize: 0,
          typeIds: [Constants.organizationType.foiv],
          isActive: true,
        }).then((response) => {
          this.organizationList = [];
          if (response.data && response.data.items.length) {
            response.data.items.forEach((i) => this.organizationList.push({ id: i.id, text: i.name }));
          }
        });
      },
    },
    computed: {
      mpkFilterList() {
        const list = [];
        if (this.mpkList) {
          this.mpkList
            .map((x) => ({ id: x.id, text: x.name }))
            .forEach(function (el) {
              list.push(el);
            });
        }

        return list;
      },
      // Возможность поставить отметку доступна, если в поле "Исполнитель" выбрано значение "ТПП России" и/или "Торгово-промышленные палаты в Российской Федерации"
      isBusinessCouncilVisible() {
        const idsSet = new Set(this.item.organizationsIds);
        return idsSet.has('830') || idsSet.has('49');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .required .form-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  .checkboxInput .hidden {
    display: none;
  }
  .block {
    &__wrap {
      display: flex;
      flex-direction: row;
    }
  }
</style>
