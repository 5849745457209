<template>
  <div class="container-page container-page-rktv pb-0">
    <div class="registry-mpk">
      <p class="registry-mpk__title title-inner title-inner--md">Реестр поручений по МПК</p>
      <div class="registry-mpk__header-container">
        <div class="registry-mpk__header-btnsBlock">
          <ButtonComponent @click="addCard" v-if="canEdit" class="registry-mpk__header-btnsBlock-item button-redesign"
            >Создать поручение</ButtonComponent
          >
          <ButtonComponent @click="showUploadData" class="registry-mpk__header-btnsBlock-item button-redesign"
            >Загрузить поручения</ButtonComponent
          >
          <ButtonComponent
            @click="dialogWordState = true"
            v-if="canEdit"
            class="registry-mpk__header-btnsBlock-item button-redesign"
            >Скачать Проект Поручения</ButtonComponent
          >
          <ButtonComponent @click="exportExcel" class="registry-mpk__header-btnsBlock-item button-redesign"
            >Скачать реестр поручений</ButtonComponent
          >
          <ButtonComponent
            @click="showExportMonitoring"
            class="registry-mpk__header-btnsBlock-item button-redesign"
            :disabled="!filter.countryId || filter.countryId === 0"
            >Скачать мониторинг</ButtonComponent
          >
          <ButtonComponent @click="goToMonitoring" class="registry-mpk__header-btnsBlock-item button-redesign"
            >Статистика исполнения поручений</ButtonComponent
          >
          <ButtonComponent @click="showExportPptxDialog" class="registry-mpk__header-btnsBlock-item button-redesign"
            >Скачать статистику</ButtonComponent
          >
        </div>
      </div>

      <div class="registry-mpk__header-filter filter">
        <div class="filter__item registry-mpk__inputBlock">
          <p class="label-field">Страна</p>
          <!-- Используется [...countryList] для корректного отображения начального выбранного значения -->
          <SelectSingleComponentCheckbox
            v-model="filter.countryId"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            placeholder="Выберите страну"
            :options="[...countryList]"
            :style="{ height: '40px' }"
            :canClear="false"
          />
        </div>

        <div class="filter__item registry-mpk__inputBlock">
          <p class="label-field">Мероприятия МПК</p>
          <SelectSingleComponentCheckbox
            v-model="filter.mpkEventId"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            placeholder="Выберите мероприятия МПК"
            :options="mpkEventList"
            :style="{ height: '40px' }"
            :canClear="false"
          />
        </div>
      </div>

      <div class="registry-mpk__info-container">
        <InfoCountList :style="{ marginBottom: 0 }" :info-count-list="legend" />
      </div>

      <div class="registry-mpk__header-container-additional" v-if="selectedIds.length">
        <span class="registry-mpk__additional-count">Выбрано: {{ selectedIds.length }}</span>
        <ButtonComponent
          class="registry-mpk__additional-item button-redesign button-redesign--transparent-bg button-redesign--md"
          @click="showStartMultipleDialog"
          >На исполнение</ButtonComponent
        >
        <ButtonComponent
          class="registry-mpk__additional-item button-redesign button-redesign--transparent-bg button-redesign--md"
          @click="showChangeStatusDialog"
          >Сменить статус</ButtonComponent
        >
        <ButtonComponent
          class="registry-mpk__additional-item button-redesign button-redesign--transparent-bg button-redesign--md"
          @click="showChangeControlDateDialog"
          >Сменить дату контроля</ButtonComponent
        >
      </div>
      <div class="mb-20">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>
    </div>
  </div>

  <AssignmentsTable
    v-model:selectAll="selectAll"
    v-model:selectedIds="selectedIds"
    :canEdit="canEdit"
    :filterProps="filter"
    :page="page"
    :mpkEventList="mpkEventList"
    :organizationList="organizationList"
    :statusList="statusList"
    :isLoading="isLoading"
    :isLoadingMore="isLoadingMore"
    @changeSorting="changeSorting"
    @changeFilter="changeFilter"
    @editCard="editCard"
    @deleteCard="deleteCard"
    @onPageChange="onPageChange"
  />
  <div class="container-page container-page-rktv">
    <div class="registry-mpk">
      <ModalComponent
        title="Форма создания поручения"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="closeDialogState"
        v-model="dialogState"
      >
        <AssignmentCardAdd :nn="dialogNn" :id="dialogId" @save="afterSaveCard" @close="dialogState = false" />
      </ModalComponent>

      <ModalComponent
        title="Выгрузить отчет"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="dialogWordState = false"
        v-model="dialogWordState"
      >
        <div class="form__body form__body--redesigned">
          <div>
            <div class="form__body-inputBlock mb-3 pb-3">
              <p>Мероприятие</p>
              <SelectSingleComponentCheckbox
                :style="{ height: '40px' }"
                v-model="dialogWordEventId"
                placeholder="Укажите мероприятие"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                :options="mpkEventList"
                :canClear="false"
                required
              />
            </div>
            <div class="form__body-inputBlock mb-3 pb-3">
              <p>Укажите формат</p>
              <SelectSingleComponentCheckbox
                :style="{ height: '40px' }"
                v-model="dialogWordFileTypeId"
                placeholder="Укажите формат"
                trackBy="text"
                valueProp="id"
                labelOptions="text"
                :options="fileTypeList"
                :canClear="false"
                required
              />
            </div>
          </div>
          <div class="form__body-inputBlock mb-0">
            <ButtonStock title="Выгрузить" @click="exportFile" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Выгрузить отчет о мониторинге"
        content-class="modal--new"
        width="632"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="dialogMonitoringWordState = false"
        v-model="dialogMonitoringWordState"
      >
        <div class="form__body form__body--redesigned">
          <div class="form__body-inputBlock mb-3 pb-3">
            <p>Мероприятие</p>
            <SelectSingleComponentCheckbox
              :style="{ height: '40px' }"
              v-model="dialogMonitoringWordEventId"
              placeholder="Укажите мероприятие"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="mpkEventByCountryList"
              :canClear="false"
              required
            />
          </div>
          <div class="form__body-inputBlock mb-3 pb-3">
            <p>Укажите формат</p>
            <SelectSingleComponentCheckbox
              :style="{ height: '40px' }"
              v-model="dialogMonitoringWordFileTypeId"
              placeholder="Укажите формат"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="fileTypeList"
              :canClear="false"
              required
            />
          </div>
          <div class="form__body-inputBlock">
            <ButtonStock title="Выгрузить" @click="exportFileMonitoring" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        v-model="importModal"
        title="Импорт файла"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="closeImportModal"
      >
        <p v-if="templatePreparing">Подготовка шаблона</p>
        <a href="javascript:" @click="getUploadTemplate" v-if="!templatePreparing">Скачать шаблон</a>
        <div class="my-2">
          <label class="control-label">Статус для новых поручений</label>
          <SelectSingleComponentCheckbox
            :style="{ height: '40px' }"
            v-model="importStatusId"
            placeholder="Статус для новых поручений"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="importStatusList"
            :canClear="false"
            required
          />
        </div>
        <ImportModalRedesigned v-if="importModal" @doimport="doImport" @close="closeImportModal" />
      </ModalComponent>

      <ModalComponent
        title="На исполнение"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="startMultipleModal = false"
        v-model="startMultipleModal"
      >
        <div class="registry-mpk__form-body">
          <div class="registry-mpk__form-body-inputBlock textarea">
            <p>Поручение</p>
            <ComponentInput
              v-model="startMultipleRazdelProtocal"
              type="textarea"
              mod="redesigned"
              placeholder="Введите"
              required
              :textareaStyles="{ 'min-height': '140px' }"
            />
          </div>
          <div class="registry-mpk__form-body-button-container">
            <ButtonComponent
              class="registry-mpk__form-body-button button-redesign button-redesign--gradient-bg"
              @click="doStartMultiple"
              >Подтвердить</ButtonComponent
            >
            <ButtonComponent class="registry-mpk__form-body-button button-redesign" @click="startMultipleModal = false"
              >Закрыть</ButtonComponent
            >
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Сменить статус"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="statusChangeModal = false"
        v-model="statusChangeModal"
      >
        <div class="registry-mpk__form-body">
          <div class="registry-mpk__form-body-inputBlock">
            <label class="control-label">Новый статус</label>
            <SelectSingleComponentCheckbox
              :style="{ height: '40px' }"
              v-model="statusChangeStatus"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="statusChangeList"
              :canClear="false"
              required
            />
          </div>
          <div class="registry-mpk__form-body-button-container">
            <ButtonComponent
              class="registry-mpk__form-body-button button-redesign button-redesign--gradient-bg"
              @click="doChangeStatus"
            >
              Подтвердить
            </ButtonComponent>
            <ButtonComponent class="registry-mpk__form-body-button button-redesign" @click="statusChangeModal = false">
              Закрыть
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Сменить срок контроля"
        content-class="modal--new"
        :themeLigth="true"
        headType="redesigned"
        bodyType="redesigned"
        @close="dateChangeModal = false"
        v-model="dateChangeModal"
      >
        <div class="registry-mpk__form-body">
          <div class="registry-mpk__form-body-inputBlock required">
            <label class="control-label">Новый срок контроля</label>
            <div class="modal-date-container">
              <dateComponent v-model="dateChangeDate" :clearable="false" modClass="redesigned" dateIcon required />
            </div>
          </div>
          <div class="registry-mpk__form-body-button-container">
            <ButtonComponent
              class="registry-mpk__form-body-button button-redesign button-redesign--gradient-bg"
              @click="doChangeDate"
            >
              Подтвердить
            </ButtonComponent>
            <ButtonComponent class="registry-mpk__form-body-button button-redesign" @click="dateChangeModal = false">
              Закрыть
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        title="Скачать статистику (презентация)"
        @close="dialogPptx.state = false"
        v-model="dialogPptx.state"
        headType="redesigned"
        bodyType="redesigned"
      >
        <div class="form__body">
          <div class="checkboxInput mb-3">
            <input type="checkbox" v-model="dialogPptx.useFilters" id="chUseFilters" style="display: none" />
            <label for="chUseFilters">Использовать текущий фильтр</label>
          </div>
          <div>
            <ButtonStock class="btn-update mr-8 mb-6" title="Скачать" @click="exportPptx" />
            <ButtonStock @click="dialogPptx.state = false" btnType="blueColorNoBorder" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ImportModalRedesigned from '@/common/components/redesigned/ImportModalRedesigned.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import InfoCountList from '@/components/InfoCountList';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API_ORG from '@/modules/admin/api/organization';

  import API from '../api/assignment';
  import AssignmentCardAdd from '../components/assigments/AssignmentCardAdd.vue';
  import AssignmentsTable from '../components/assigments/AssignmentsTable';

  export default {
    name: 'Assignments',
    components: {
      ButtonStock,
      ModalComponent,
      AssignmentCardAdd,
      ComponentInput,
      DefaultFilter,
      dateComponent,
      InfoCountList,
      ImportModalRedesigned,
      SelectSingleComponentCheckbox,
      AssignmentsTable,
      ButtonComponent,
    },
    mixins: [security, filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          countryId: this.$route.query?.countryId ?? null,
          mpkEventId: this.$route.query?.mpkEventId ?? null,
          brief: '',
          srokFrom: null,
          srokTo: null,
          organizationId: null,
          mpkRegistryStatusId: null,
          report: '',
          razdelProtocal: '',
          noPoint: '',
          // Сортировка столбцов
          // 0 - название заседания
          // 1 - № поручения
          // 2 - № п/п
          // 3 - поручение
          // 4 - срок исполнения
          // 5 - исполнитель
          // 6 - статус
          // 7 - отчет
          sortColumn: 0,
          sortOrder: 0,
        },
        page: {},
        totalData: {},
        canEdit: false,
        isLoading: false,
        isLoadingMore: false,

        statusList: [],
        organizationList: [],
        countryList: [{ id: 0, text: 'Все', selected: true }],
        fileTypeList: [
          { id: 1, text: 'Документ Microsoft Word (DOCX)', selected: true },
          { id: 2, text: 'Документ PDF' },
        ],
        importStatusList: [
          { id: 1, text: 'Проект поручения', selected: true },
          { id: 2, text: 'Новое' },
        ],
        importStatusId: null,
        mpkEventList: [],
        mpkEventByCountryList: [],

        dialogState: false,
        dialogNn: 1,
        dialogId: 0,

        dialogMonitoringWordState: false,
        dialogMonitoringWordEventId: 0,
        dialogMonitoringWordFileTypeId: 1,
        dialogWordState: false,
        dialogWordEventId: 0,
        dialogWordFileTypeId: 1,
        templatePreparing: false,
        importModal: false,
        selectedIds: [],
        selectAll: false,
        startMultipleModal: false,
        startMultipleRazdelProtocal: null,
        statusChangeModal: false,
        statusChangeStatus: 5,
        statusChangeList: [
          { id: 5, text: 'Выполнено', selected: true },
          { id: 6, text: 'Не выполнено' },
          { id: 7, text: 'Не выполнено по вине иностранной стороны' },
          { id: -1, text: 'Не выполнено в связи с потерей актуальности' },
        ],
        dateChangeModal: false,
        dateChangeDate: null,
        dialogPptx: {
          state: false,
          useFilters: false,
        },
        legend: [],
      };
    },
    created() {
      this.isLoading = true;
      this.checkPermissionAndRedirect(Constants.Permissions.MpkAssignmentAccess);
      this.checkPermission(Constants.Permissions.MpkAssignmentEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadFilter().then(() => {
        this.loadGroupData();
        this.loadPage(false);
        Utils.loadSelectOptions('countryList', this.countryList);

        API.getMpkRegistryAssignmentStatusList().then((response) => {
          if (response.data && response.data.length) {
            response.data.forEach((i) => this.statusList.push({ id: i.id, text: i.text }));
          }
        });

        this.loadOrganization();

        this.loadMpkEvent();
      });
    },
    methods: {
      parseParamId(value) {
        return Number(value) === 0 ? null : value;
      },
      loadMpkEvent(params = {}) {
        const countryId = this.parseParamId(this.filter.countryId);
        const newParams = {
          countryId,
          ...params,
        };
        API.getEventForList(newParams).then((response) => {
          this.mpkEventList = [];
          if (response.data && response.data.length) {
            this.mpkEventList = [{ id: 0, text: 'Все', selected: true }];
            response.data.forEach((i) => this.mpkEventList.push(i));
          }
        });
      },
      loadOrganization() {
        API_ORG.searchShortFormat({
          pageNumber: 1,
          pageSize: 0,
          typeIds: [Constants.organizationType.foiv],
          isActive: true,
        }).then((response) => {
          this.organizationList = [];
          if (response.data && response.data.items.length) {
            response.data.items.forEach((i) => this.organizationList.push({ id: i.id, text: i.name }));
          }
        });
      },
      goToMonitoring() {
        this.$router.push({ name: 'AssignmentMonitoring' });
      },
      addCard() {
        this.dialogState = true;
        this.dialogNn++;
        this.dialogId = 0;
      },
      editCard(id) {
        this.dialogState = true;
        this.dialogNn++;
        this.dialogId = id;
      },
      closeDialogState() {
        this.dialogState = false;
        this.dialogId = 0;
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.deleteExecutor(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      afterSaveCard() {
        this.closeDialogState();
        this.loadPage(false);
      },

      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadGroupData() {
        API.getTotal({ countryId: this.filter.countryId }).then((response) => {
          this.totalData = response.data;
          const notExecuteCount = this.totalData?.notExecute + this.totalData?.overdue;
          this.legend = [
            { title: 'Всего поручений', count: this.totalData.totalCount },
            { title: 'Выполнено', count: this.totalData.done },
            { title: 'В стадии выполнения', count: this.totalData.inWork },
            { title: 'Не выполнено', count: Number.isNaN(notExecuteCount) ? '' : notExecuteCount },
          ];
        });
      },
      loadPage(addToExist) {
        addToExist ? (this.isLoadingMore = true) : (this.isLoading = true);
        const params = { ...this.filter, ...this.request };

        API.search(params)
          .then((response) => {
            if (!addToExist) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.isLoadingMore = false;
          });
      },
      exportExcel() {
        const params = Object.assign(this.filter, this.request);
        API.exportExcel(params);
      },

      exportFile() {
        if (this.dialogWordEventId !== null && this.dialogWordFileTypeId) {
          API.exportFile({
            mpkEventId: this.dialogWordEventId,
            fileTypeId: this.dialogWordFileTypeId,
          });
          this.dialogWordEventId = null;
          this.dialogWordFileTypeId = 1;
          this.dialogWordState = false;
        } else {
          Constants.alert.fire('Отчет', 'Выберите мероприятие и тип файла', 'error');
          this.dialogWordEventId = null;
          this.dialogWordFileTypeId = 1;
        }
      },

      showExportMonitoring() {
        this.dialogMonitoringWordFileTypeId = 1;
        this.dialogMonitoringWordEventId = 0;
        API.getEventForList({ countryId: this.filter.countryId }).then((response) => {
          this.mpkEventByCountryList = [];
          if (response.data && response.data.length) {
            response.data.forEach((i) => this.mpkEventByCountryList.push(i));
          }
          this.dialogMonitoringWordState = true;
        });
      },
      exportFileMonitoring() {
        if (this.dialogMonitoringWordEventId > 0 && this.dialogMonitoringWordFileTypeId) {
          API.exportFileMonitoring({
            mpkEventId: this.dialogMonitoringWordEventId,
            fileTypeId: this.dialogMonitoringWordFileTypeId,
          });
          this.dialogMonitoringWordState = false;
        } else {
          Constants.alert.fire('Отчет о мониторинге', 'Выберите мероприятие', 'error');
        }
      },
      changeSorting(id) {
        if (this.filter.sortColumn == id) this.filter.sortOrder = this.filter.sortOrder == 0 ? 1 : 0;
        else this.filter.sortOrder = 0;
        this.filter.sortColumn = id;
        this.loadPage(false);
      },
      showUploadData() {
        this.importModal = true;
      },
      getUploadTemplate() {
        this.templatePreparing = true;
        API.getUploadTemplate().then(() => {
          this.templatePreparing = false;
        });
      },
      doImport(file) {
        API.uploadFile(file, this.importStatusId)
          .then((response) => {
            if (response.data.success)
              Constants.alert.fire(
                'Импорт',
                'Импорт успешно выполнен. Добавлено записей: ' + response.data.addedCount,
                'success',
              );
            else {
              Constants.alert.fire({
                title: 'Импорт',
                icon: 'error',
                html:
                  'Ошибок при импорте данных: ' +
                  response.data.errors.length +
                  '. <a href="javascript:" class="import-alert-link">Скачать отчет</a>.',
              });
              const elements = document.getElementsByTagName('a');
              for (var i = 0; i < elements.length; i++) {
                if (elements[i].className == 'import-alert-link') {
                  elements[i].onclick = function () {
                    let txtContent = 'data:text/txt;charset=utf-8,' + response.data.errors.join('\n');
                    const encodedUri = encodeURI(txtContent);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', 'Отчет об ошибках.txt');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  };
                }
              }
            }
            this.loadPage();
          })
          .catch(() => {
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
          });
        this.closeImportModal();
      },
      closeImportModal() {
        this.importModal = false;
        this.importStatusId = null;
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      exportPptx() {
        const filter = this.dialogPptx.useFilters ? this.filter : {};
        API.exportPptx(filter);
        this.dialogPptx.state = false;
      },
      clearSelection() {
        this.selectedIds = [];
      },
      showStartMultipleDialog() {
        if (this.selectedIds && this.selectedIds.length) {
          this.v$.$reset();
          this.startMultipleRazdelProtocal = null;
          this.startMultipleModal = false;
          this.startMultipleModal = true;
        }
      },
      doStartMultiple() {
        this.v$.$touch();
        if (this.v$.startMultipleRazdelProtocal.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        const itemToSave = {
          Ids: this.selectedIds,
          razdelProtocal: this.startMultipleRazdelProtocal,
        };

        API.startMultiple(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadPage();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          })
          .finally(() => {
            this.startMultipleModal = false;
          });
      },
      showChangeStatusDialog() {
        if (this.selectedIds && this.selectedIds.length) {
          this.statusChangeStatus = 5;
          this.statusChangeModal = false;
          this.statusChangeModal = true;
        }
      },
      doChangeStatus() {
        const itemToSave = {
          ids: this.selectedIds,
          status: this.statusChangeStatus,
        };

        API.changeStatus(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadPage();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          })
          .finally(() => {
            this.statusChangeModal = false;
          });
      },
      showChangeControlDateDialog() {
        if (this.selectedIds && this.selectedIds.length) {
          this.v$.$reset();
          this.dateChangeDate = null;
          this.dateChangeModal = false;
          this.dateChangeModal = true;
        }
      },
      doChangeDate() {
        this.v$.$touch();
        if (this.v$.dateChangeDate.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        const itemToSave = {
          ids: this.selectedIds,
          date: this.dateChangeDate,
        };

        API.changeDate(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadPage();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          })
          .finally(() => {
            this.dateChangeModal = false;
          });
      },
      showExportPptxDialog() {
        this.dialogPptx.state = false;
        this.dialogPptx.useFilters = false;
        this.$nextTick(() => {
          this.dialogPptx.state = true;
        });
      },
    },
    watch: {
      selectAll: function (value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },
      filter: {
        handler() {
          this.changeFilter();
        },
        deep: true,
      },
      'filter.countryId'(newValue, oldValue) {
        if (newValue !== oldValue) {
          const countryId = this.parseParamId(newValue);
          this.filter.mpkEventId = null;
          this.loadMpkEvent();
          this.$router.replace({
            query: countryId ? { countryId } : null,
          });
        }
      },
      'filter.mpkEventId'(newValue, oldValue) {
        if (newValue !== oldValue) {
          const countryId = this.parseParamId(this.filter.countryId);
          const mpkEventId = this.parseParamId(newValue);
          this.loadMpkEvent();
          this.$router.replace({
            query: {
              countryId,
              mpkEventId,
            },
          });
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        startMultipleRazdelProtocal: { required },
        dateChangeDate: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '../styles/Assignments.scss';
</style>
